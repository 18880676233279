<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->

            <div class="">
                <h1>Referrals</h1>
                <div class="flex colx4 wrap">
                    <div><Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        v-model="record_data.name"
                        label="Name"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="employer"
                        id="employer"
                        placeholder="Employer"
                        v-model="record_data.employer"
                        label="Employer"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        v-model="record_data.phone"
                        label="Phone"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        v-model="record_data.email"
                        label="Email"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="notes"
                        id="notes"
                        placeholder="Notes"
                        v-model="record_data.notes"
                        label="Notes"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="Referring Provider NPI"
                        id="referring_provider_npi"
                        placeholder="Referring Provider NPI"
                        v-model="record_data.referring_provider_npi"
                        label="Referring Provider NPI"
                        :disabled="!isEditable"
                    /></div>
                </div>
                <!--form_extra-->
            </div>


            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'referrals'

    export default {
        name: 'ReferralDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    name : '',
                    employer : '',
                    email : '',
                    user_id : 1,
                    notes : '',
                    referring_provider_npi: ''
                },
                isEditable : 1,
                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }

                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        const record_id = parseInt(res.data.recId[0])
                        this.$toasted.success('Successfully updated Record.')
                        await this.$router.push({ name: 'ReferralDetail', params: { record_id: record_id } })

                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
